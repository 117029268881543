import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { useMnemonicPhraseStore } from '@/entities/mnemonic';
import {
  AccountStatus,
  useLoginViaPersonalAccountCodeMutation,
} from '@/shared/generated/graphql';
import { useInitWhoAmi } from '@/shared/hooks/useInitWhoAmi';
import { useAuthStore } from '@/shared/store/auth.store';
import { ERegisrationStep } from '@/shared/store/registration.store';

// SSO auth legacy
export const useAuth = () => {
  const router = useRouter();
  const [login] = useLoginViaPersonalAccountCodeMutation();
  const { setToken, setAccount } = useAuthStore();
  const { mnemonicPhrase } = useMnemonicPhraseStore();
  const { initWhoAmi } = useInitWhoAmi();
  const [loading, setLoading] = useState(false);

  const appLogin = async (authGrantToken: string) => {
    const resLogin = await login({
      variables: {
        code: authGrantToken,
      },
    });
    const loginData = resLogin.data?.loginViaPersonalAccountCode;
    setToken(loginData?.token);
    setAccount(loginData?.account);

    if (loginData?.token) {
      const profile = await initWhoAmi(loginData?.token);

      if (profile?.status !== AccountStatus.Active) {
        await router.push(
          `/auth/register?step=${ERegisrationStep.CREATE_SEED}`,
        );
        return;
      }
    }

    const isMnemonic = !mnemonicPhrase || mnemonicPhrase.length === 0;
    if (isMnemonic) {
      if (router.query) {
        await router.push(
          {
            pathname: '/auth/login/mnemonic-phrase',
            query: { reset: true },
          },
          undefined,
        );
        setLoading(false);

        return;
      }
      await router.push('/auth/login/mnemonic-phrase', undefined);
    }
  };

  return useMemo(
    () => ({
      loading,
      appLogin,
    }),
    [loading, appLogin],
  );
};
